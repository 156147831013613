* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style-type: none;
	text-decoration: none;
	font-size: 16px;
}

.container {
	background-color: #fff;
}

nav {
	height: 40px;
	position: fixed;
	width: 100%;
	z-index: 100;
	top: 0;
}

#bg-image {
	background-image: linear-gradient(to bottom, rgba(214, 209, 209, 0.05), rgba(71, 68, 68, 0.4)),
		url('./assets/monile-header.jpg');
	height: 80vh;
	max-width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
}

#triot-bg {
	background-image: url('./assets/triot.jpg');
	height: 80vh;
	max-width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	-webkit-box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
}

nav .logo,
nav .menu {
	display: none;
}

.logo {
	color: rgba(255, 255, 255, 0.90);
	font-family: 'Montserrat', sans-serif;
	font-size: 1.4rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: bold;
}

h1 {
	font-family: 'Anton', sans-serif;
	font-size: 1.6rem;
	padding: 10px;
	letter-spacing: 2px;
	text-align: center;
}

h2 {
	font-family: 'Anton', sans-serif;
	letter-spacing: 2px;
	font-weight: 200;
	text-align: center;
	font-size: 1.5rem;
}

.hero-flip {
	font-size: 2.5rem;
	opacity: 0.8;
	color: #75accb;
	position: absolute;
	top: 40%;
	left: 4%;
	text-align: left;
}

section {
	margin: 20px 10px;
	color: rgba(0, 0, 0, 0.75);
}

section img {
	width: 100%;
}

.info-text {
	font-family: 'Merriweather Sans', sans-serif;
	padding: 12px;
	line-height: 26px;
	font-size: 15px;
}

/* social media */

.media-icons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: .5rem;
}

.social-media-li a img {
	width: 3rem;
	padding: 5px;
	margin: 0 5px;
	border-radius: 50%;
}

/* footer */

.copyright {
	font-family: 'Merriweather Sans', sans-serif;
	font-size: 12px;
	color: #fff;
	background-color: #75accb;
	padding: 15px;
	text-align: center;
}

.copyright a {
	font-size: 12px;
	color: #fff;
}

/* media queris */

@media screen and (min-width: 620px) {
	#bg-image {
		height: 85vh;
		background-image: url('./assets/hero-desktop-fl.jpg');
	}
}

@media screen and (min-width: 1033px) {
	nav {
		display: grid;
		position: fixed;
		z-index: 99;
		width: 100%;
		grid-template-columns: 70vw auto;
		-webkit-box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.75);
	}

	nav .toggle-button {
		display: none;
	}

	nav .logo,
	nav .menu {
		display: inline-flex;
	}

	nav .logo {
		margin: 10px;
	}

	nav ul {
		display: flex;
		justify-content: space-around;
		align-items: center;
		letter-spacing: 1px;
		background-color: #75accb;
	}

	nav ul li a {
		color: rgb(53, 48, 48);
		font-family: 'Merriweather Sans', sans-serif;
		text-decoration: none;
		padding: 5px;
		margin-right: .5rem;
		font-size: 1rem;
	}

	#bg-image {
		display: none;
	}

	#triot-bg {
		display: none;
	}

	.header-class {
		background-image: url('./assets/hero-desktop-fl.jpg');
		background-position: top center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 70vw;
		height: 100vh;
		position: fixed;
		background-color: #000;
		transition-property: opacity 1;
		transition: opacity 2s ease;
	}

	.image {
		background-image: url('./assets/triot.jpg');
	}
	.book {
		background-image: url('./assets/cover_book.jpg');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		width: 70vw;
		height: 100vh;
		position: fixed;
	}

	.header-class > div {
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: opacity 2s ease;
	}

	.header-class .header-logo {
		opacity: 1;
	}

	#fl-1 {
		background-image: url('./assets/francesco_top.jpg');
		background-size: cover;
	}

	#fl-2 {
		background-image: url('./assets/main_large.jpg');
		background-position: top;
		background-size: auto 100%;
	}

	.section1 #fl-1,
	.section2 #fl-2 {
		opacity: 1;
	}

	#triot-bg-2 {
		background-image: url('./assets/bjc_founders.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
	}

	#triot-bg-3 {
		background-image: url('./assets/triot.jpg');
		background-size: cover;
	}

	#book-bg {
		background-image: url('./assets/libro_foto.jpg');
		background-size: cover;
	}

	.section4 #triot-bg-3,
	.section5 #triot-bg-2 {
		opacity: 1;
	}

	nav ul,
	.copyright {
		transition: background 0.2s ease;
	}

	.section1 nav ul,
	.section1 .copyright {
		background: #75accb;
	}

	.section2 nav ul,
	.section2 .copyright {
		background: #f9c943;
	}
	.section5 nav ul,
	.section5 .copyright,
	.section4 nav ul,
	.section4 .copyright {
		background: #f9c943;
	}

	.hero-flip {
		transform: color 0.2s ease;
	}

	.section1 .hero-flip {
		color: #f9c943;
	}

	.section2 .hero-flip {
		color: #f9c943;
	}

	.hero-flip {
		font-size: 4rem;
		letter-spacing: .4rem;
	}

	section {
		margin: 20px 0 0 0;
	}

	section img {
		display: none;
	}

	.social-media-li a img {
		display: block;
	}

	.side-bar {
		display: grid;
		grid-template-columns: 70vw auto;
	}

	.primary-section {
		grid-column-start: 2;
		margin-top: 80px;
		height: 105%;
	}

	.primary-section h1 {
		font-size: 1.6rem;
	}

	.pro {
		margin: 1rem 0;
	}

	.contacts {
		font-size: 1.5rem;
		padding-top: 1rem;
	}

	.social-media-list {
		grid-row-start: 2;
		grid-row-end: 2;
		grid-column-start: 2;
		background-color: #fff;
		box-shadow: 0px -4px 5px -2px rgb(221, 221, 221);
	}

	footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: 99;
		display: grid;
		grid-template-columns: 70vw auto;
	}

	.spacer {
		grid-column: 1;
		display: grid;
		align-items: center;
		justify-items: center;
	}

	.copyright {
		background-color: #75accb;
		grid-column: 2;
	}
}
