.iframe-container {
	position: fixed;
	width: 100%;
	padding-bottom: 56.25%;
	height: 0;
	top: 0px;
}

.iframe-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



@media screen and (min-width: 1024px) {
	.header-class-video-selected {
		width: 70vw;
		height: 100vh;
		position: fixed;
		background-color: #000;
	}

	.header-class-video-selected > iframe {
		position: absolute;
		height: 100%;
		width: 100%;
	}

	.iframe-container {
		position: static;
		height: 0;
	}
}
